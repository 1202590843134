import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <p>&copy; 2024 Bootcamp. All rights reserved.</p>
        <nav className="footer-nav">
          <a href="/">Home</a>
          <a href="./about">About</a>
          <a href="./programs">Programs</a>
          <a href="#testimonials">Testimonials</a>
          <a href="#contact">Contact</a>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;