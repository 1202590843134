import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Programs from './components/Programs';
import Welcome from './components/Welcome';
import CallToAction from './components/CallToAction';
import Faq from './components/Faq';
import Footer from './components/Footer';
import ProgramForm from './components/ProgramForm';

function App() {
  return (

    <Router>
       <Header />
        <Welcome/>

      <div className="App">
        
       
        

        <div id="welcome">
         
        </div>
        <div id="hero">
          <Hero />
        </div>
        <div id="about">
          <About />
        </div>
        <div id="cta">
          <CallToAction />
        </div>
        <div id="programs">
          <Programs />
        </div>
        <div id="enroll">
          <ProgramForm />
        </div>
        <div id="faq">
          <Faq />
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
