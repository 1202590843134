
import './Welcome.css';
import image1 from '../images/image1.jpg';  // Import your image here
import image2 from '../images/image2.jpg'; 
import image3 from '../images/image3.jpg'; 
import image5 from '../images/image5.jpg'; 
import image6 from '../images/image6.jpg'; 
import React, { useState, useEffect } from 'react';


const images = [
   image1, image3, image6, image2, image5  // Use the imported image here
 ];
 

const Welcome = () => {

      const [currentImageIndex, setCurrentImageIndex] = useState(0);
    
      useEffect(() => {
        const interval = setInterval(() => {
          setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000); // Change image every 3 seconds
    
        return () => clearInterval(interval);
      }, []);
    
      return (
        <section className="welcom">
          <div className="welcom-image">
         <img className='welcoming' src ={images[currentImageIndex]
          
         }/>
                
         </div>
          <div className="welcom-content">
            <h1 className="welcom-headline">
            Join Code4All Bootcamp and kickstart your tech career with the most affordable  <br></br>coding Boot Camp program.
            </h1>
            <p className="welcom-subheadline">
            A Programming Course guided by instructors, mentors and supported by a vibrant community of peers
             with a maximum of 20 active students.
               <br></br>Learn Cybersecurity and Become Cybersecurity Analyst + Ethical Hacker
              <br></br> Participate in live workshops  every   week.
            </p>
          
            <a href="#programs" className="cta-button">Get Started</a>
          </div>
        </section>
      );
    };

export default Welcome;