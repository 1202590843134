import React from 'react';
import './Header.css';
import logo from '../images/logo.jpg';  // Import the image
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="header">
      <div className="containerheader">
    
         <img className = "logo" src={logo} alt='logo' />
         
      
        <nav className="nav">
        <ul>
          <li><a href="/">Home</a></li>
        
          <li><a href="#about">About</a></li>
         
          <li><a href="#programs">Programs</a></li>
          <li><a href="#enroll">Enroll</a></li>
          <li className='portal'>Student Portal</li>
        </ul>
        </nav>
        <div >
        <Link to="/enroll"><button className='apply'>Apply Now</button></Link>
        </div>
      </div>
    </header>
  );
};

export default Header;

