import React from 'react';
import "./About.css"


const About = () => {
  return (
    <section className="about" id="about">
  <h2 className= "why">Why Choose Us?</h2> 
      <div className="container2">
      
        <p className="about-subheadline"><span className='why2'>At Code 4 All, we believe that financial constraints should never exclude anyone from education. 
        </span>
        <br></br> <span className='why3'>For $59.99 per week, receive the same high-quality education, with in-person teacher sessions every Week.
         <p></p>Our program, which costs $5,000 per semester at other bootcamps, is available to you for less than $969.99  <br>
        </br> </span></p><span className='why'> Claim your FREE First Week Session</span>
        
     
        <div className="features">
          <div className="feature">
            
            <h3 className="feature-title">Expert Instructors</h3>
            <p className="feature-description">Gain knowledge from experienced experts who bring practical insights to your learning journey..</p>
          </div>
          <div className="feature">
           
            <h3 className="feature-title">Hands-On Projects</h3>
            <p className="feature-description">Engage in immersive projects that simulate industry challenges and solutions. Build a robust portfolio with hands-on projects designed to mirror professional scenarios.</p>
          </div>
          <div className="feature">
           
            <h3 className="feature-title">Career Support</h3>
            <p className="feature-description">Benefit from personalized career services, including resume building, interview preparation, and job placement assistance.</p>
          </div>
          <div className="feature">
       
            <h3 className="feature-title">Flexible Learning</h3>
            <p className="feature-description">Full-time, part-time, and self-paced.
            Study at your own pace with a schedule that fits your lifestyle and commitments.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;