import React from 'react';
import './Faq.css';

const Faq = () => {
  return (
    <section className="faq" id="faq">
      <div className="container">
        <h2 className="faq-headline">Frequently Asked Questions</h2>
        <div className="faq-accordion">
          <div className="faq-item">
            <button className="faq-question">What is the duration of the program?</button>
            <div className="faq-answer">
              <p>The program duration is 12 weeks...</p>
            </div>
          </div>
          <div className="faq-item">
            <button className="faq-question">What are the prerequisites?</button>
            <div className="faq-answer">
              <p>There are no prerequisites...</p>
            </div>
          </div>
          <div className="faq-item">
            <button className="faq-question">What is the job placement rate?</button>
            <div className="faq-answer">
              <p>Our job placement rate is 90%...</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;