import React from 'react';
import './Hero.css';
import image8 from '../images/image8.jpg';  // Import your image here
import image1 from '../images/code.gif';  




const Hero = () => {

  return (
    <section className='mainHero' >
    
        <div className="hero-left" >       
      
        <img className="imageHero"src={image1}/>

        </div>

        <div className="hero-right">
          <img className="imagess" src={image8} alt="imag" />
    
        </div>
    
    
       
      
    </section>
  );
};

export default Hero;